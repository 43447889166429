import React from "react";
import PropTypes from 'prop-types';

export default function Brand({ title, slug, ...props }) {
  return (
    <div {...props}>
      <svg width="39" height="30" viewBox="0 0 39 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M7.49968 0L0 7.4984L7.49968 14.9994L0 22.5003L7.49968 30L19.0107 18.4903L15.5198 14.9994L19.0107 11.5085L7.49968 0Z" fill="#FFE500"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M30.5213 0L19.009 11.5085L22.5012 14.9994L19.009 18.4903L30.5213 30L38.0197 22.5003L30.5213 14.9994L38.0197 7.4984L30.5213 0Z" fill="#EB1C24"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M19.0104 0.425659L15.5208 3.91528L19.0104 7.40746L22.5 3.91528L19.0104 0.425659Z" fill="#231F20"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M19.0104 22.5929L15.5208 26.0825L19.0104 29.5734L22.5 26.0825L19.0104 22.5929Z" fill="#231F20"/>
      </svg>
    </div>
  );
}

Brand.propTypes = {
  title: PropTypes.node,
  slug: PropTypes.node,
};

Brand.defaultProps = {
  title: null,
  slug: null,
};