import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

const SEO = () => {
  const strapiGlobal = useStaticQuery(query);
  // Merge default and page-specific SEO values
  const fullSeo = {...strapiGlobal.site.siteMetadata,};

  const getMetaTags = () => {
    const tags = [];

    if (fullSeo.keywords) {
      tags.push(
        {
          name: "keywords",
          content: fullSeo.keywords,
        },
      );
    }
    if (fullSeo.metaTitle) {
      tags.push(
        {
          property: "og:title",
          content: fullSeo.metaTitle,
        },
        {
          name: "twitter:title",
          content: fullSeo.metaTitle,
        }
      );
    }
    if (fullSeo.metaDescription) {
      tags.push(
        {
          name: "description",
          content: fullSeo.metaDescription,
        },
        {
          property: "og:description",
          content: fullSeo.metaDescription,
        },
        {
          name: "twitter:description",
          content: fullSeo.metaDescription,
        }
      );
    }
    if (fullSeo.shareImage) {
      const imageUrl =
        (process.env.GATSBY_ROOT_URL || "http://localhost:8000") +
        fullSeo.shareImage.publicURL;
      tags.push(
        {
          name: "image",
          content: imageUrl,
        },
        {
          property: "og:image",
          content: imageUrl,
        },
        {
          name: "twitter:image",
          content: imageUrl,
        }
      );
    }
    if (fullSeo.article) {
      tags.push({
        property: "og:type",
        content: "article",
      });
    }
    tags.push({ name: "twitter:card", content: "summary_large_image" });

    return tags;
  };

  const metaTags = getMetaTags();

  return (
    <Helmet
      title={fullSeo.metaTitle}
      titleTemplate={`%s | ${fullSeo.siteName}`}
      link={[
        {
          rel: "stylesheet",
          href: "https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;700&display=swap",
        },
        {
          rel: "stylesheet",
          href: "https://use.typekit.net/nne1vve.css",
        }
      ]}
      meta={metaTags}
    />
  );
};

const query = graphql`
  query MyQuery {
    site {
      siteMetadata {
        siteUrl
        siteName
        keywords
        shareImage {
          publicURL
        }
        metaDescription
        metaTitle
      }
    }
  }
`;

export default SEO;