import React, {useRef, useState, useEffect} from 'react';
import Link from 'gatsby-link';

import Brand from '../../elements/Brand/Brand';
import AppButton from '../../elements/AppButton/AppButton';
import {connectionsSVG, exploreSVG, helpSVG} from './icons.js';
import './Navbar.css';

const Navbar = ({title, pathname, ...props}) => {
  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useState(false);

  const toggleMenu = () => setIsActive(!isActive);

  useEffect(() => {
    const pageClickEvent = (e) => {
      // If click happens outside the dropdown menu
      if (dropdownRef.current !== null && !dropdownRef.current.contains(e.target)) {
        setIsActive(!isActive);
      }
    };
    // listen for clicks if dropdown is active, else remove listener
    if (isActive) {
      window.addEventListener('click', pageClickEvent);
    }
    return () => {
      window.removeEventListener('click', pageClickEvent);
    }
  }, [isActive]);

  return (
    <div className='px-4 py-6 md:px-6 lg:px-10 lg:py-8' {...props}>
      <div className='flex justify-between items-center'>
        <div className='flex items-center'>
          <Link to='/' className='space-x-4 md:space-x-2 flex'>
            <Brand />
            <p className='uppercase hidden lg:block font-extrabold font-heading text-2xl'>Connections</p>
          </Link>
          
          
          <div className='block md:hidden'>
            <button className='uppercase text-sm text-gray-600 font-bold' onClick={toggleMenu}>Menu</button>
          </div>
        </div>

        <nav className='hidden md:flex items-center'>
          <ul className='flex space-x-12 uppercase font-bold text-sm text-gray-600'>
            <li>
              <Link to='/' activeClassName='text-red-500 font-black' className='cursor-pointer hover:text-red-500 flex flex-col justify-center items-center'>
                <div className='hidden lg:block mb-2'>{connectionsSVG}</div>
                <p className='font-heading'>Connections</p>
              </Link>
            </li>
            <li>
              <Link to='/explore' activeClassName='text-red-500 font-black' className='cursor-pointer hover:text-red-500 flex flex-col justify-center items-center'>
                <div className='hidden lg:block mb-2'>{exploreSVG}</div>
                <p className='font-heading'>Explore</p>
              </Link>
            </li>
            <li>
              <Link to='/help' activeClassName='text-red-500 font-black' className='cursor-pointer hover:text-red-500 flex flex-col justify-center items-center'>
                <div className='hidden lg:block mb-2'>{helpSVG}</div>
                <p className='font-heading'>Get help</p>
              </Link>
            </li>
          </ul>
        </nav>

        <div className='flex items-center'>
          <AppButton type='ios' color='white' size='small' link='https://apps.apple.com/app/id1560206793'/>
          <AppButton type='android' color='white' size='small' link='https://play.google.com/store/apps/details?id=com.ncsa.ncsa'/>
        </div>
      </div>

      <div className='relative md:hidden'>
        <nav ref={dropdownRef} className={`absolute menu bg-white font-semibold font-heading px-4 py-2 mt-2 w-full z-10 ${isActive?'active':''}`}>
          <ul>
            <Link to='/' activeClassName='text-red-500'><li className="mb-2 hover:text-red-500">Connections</li></Link>
            <Link to='/explore' activeClassName='text-red-500'><li className="mb-2 hover:text-red-500">Explore</li></Link>
            <Link to='/help' activeClassName='text-red-500'><li className="hover:text-red-500">Get help</li></Link>
          </ul>
        </nav>
      </div>
    </div>
  );
};

Navbar.propTypes = {
};

export default Navbar;