import React from 'react';

const androidSVG = <svg width="100%" height="100%" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="1" y="0" width="11" height="9">
<path d="M1.42694 0.666687H11.1838V8.41771H1.42694V0.666687Z" fill="black"/>
</mask>
<g mask="url(#mask0)">
<path fillRule="evenodd" clipRule="evenodd" d="M11.1838 5.76087L2.72232 0.86728C2.29718 0.620572 1.82567 0.612842 1.42694 0.775811L8.66716 8.41733L11.1838 5.76087Z" fill="currentColor"/>
</g>
<path fillRule="evenodd" clipRule="evenodd" d="M0.79502 1.26251C0.612082 1.50536 0.5 1.81004 0.5 2.15272V15.8473C0.5 16.1887 0.610149 16.4902 0.790511 16.7324L8.12091 8.99486L0.79502 1.26251Z" fill="currentColor"/>
<mask id="mask1" mask-type="alpha" maskUnits="userSpaceOnUse" x="1" y="9" width="11" height="9">
<path d="M1.41919 9.57074H11.1903V17.3334H1.41919V9.57074Z" fill="black"/>
</mask>
<g mask="url(#mask1)">
<path fillRule="evenodd" clipRule="evenodd" d="M1.41919 17.22C1.81985 17.3855 2.29394 17.3797 2.7223 17.1324L11.1903 12.2343L8.66649 9.57074L1.41919 17.22Z" fill="currentColor"/>
</g>
<path fillRule="evenodd" clipRule="evenodd" d="M14.5602 7.71428L11.8902 6.16962L9.21381 8.9942L11.8973 11.8265L14.5602 10.2857C15.5484 9.71436 15.5484 8.28564 14.5602 7.71428Z" fill="currentColor"/>
</svg>;

const iosSVG = <svg width="100%" height="100%" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M12.0341 3.20511C12.7333 2.3427 13.2072 1.18478 13.0818 0C12.0596 0.0502844 10.8115 0.666096 10.0882 1.52988C9.43933 2.269 8.86553 3.47651 9.0157 4.61032C10.164 4.70882 11.3102 4.0441 12.0341 3.20511ZM13.0688 4.83212C11.4018 4.73431 9.9835 5.76617 9.18722 5.76617C8.39093 5.76617 7.17171 4.88172 5.85329 4.90514C4.13605 4.92993 2.54348 5.88809 1.67211 7.41247C-0.118837 10.4612 1.19958 14.9827 2.94162 17.4666C3.7875 18.6948 4.80765 20.0476 6.15156 19.9987C7.42106 19.9498 7.9184 19.1873 9.46137 19.1873C11.0037 19.1873 11.4521 19.9987 12.7953 19.9739C14.1888 19.9498 15.0602 18.745 15.906 17.5155C16.8773 16.1151 17.274 14.7629 17.2988 14.6885C17.274 14.6644 14.6124 13.6553 14.5869 10.6327C14.5621 8.10129 16.6768 6.89654 16.7767 6.82214C15.5823 5.07803 13.7156 4.88172 13.0688 4.83143" fill="currentColor"/>
</svg>;

export {androidSVG, iosSVG}

