import React from "react";
import styled from 'styled-components';
import Helmet from 'react-helmet';
import PropTypes from "prop-types";
import tw from 'twin.macro';

import Seo from "components/lib/seo";
import Navbar from "components/layouts/Navbar/Navbar";
import NCSAFooter from "components/layouts/NCSAFooter/Footer";

const Layout = styled.div``;

const PageContainer = tw.div`
  relative min-h-screen pb-64 md:pb-52
`;

const PageLayout = ({ children, seo, ...props }) => {

  return (
    <Layout {...props}>
      <Seo />
      <Helmet>
        <html lang="en" />
      </Helmet>
      <PageContainer>
        <Navbar />
        <main>
          {children}
        </main>
        <NCSAFooter />
      </PageContainer>
    </Layout>
  );
};

PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PageLayout;