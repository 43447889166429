import React from 'react';
import PropTypes from 'prop-types';
import {androidSVG, iosSVG} from './appIcon';

// const getButtonIcon = (type) => {
//   // returns an icon type with respect to the background color
//   return (type==='android' && androidSVG || iosSVG );
// }

const AppButton = ({type, cta='Get App', color='gray', size='small', link, ...props }) => {
  const buttonIcon = type.toLowerCase() === 'android' ? androidSVG : iosSVG;
  const contentColor = color.toLowerCase() === 'black' ? 'text-white' : 'text-gray-900';
  const fontSize = size.toLowerCase() === 'small' ? 'text-xs' : 'text-sm';
  let buttonColor;
  let iconSize;
  let buttonDimensions;

  switch(color) {
    case 'gray':
      buttonColor = 'bg-gray-100';
      break;
    case 'black':
      buttonColor = 'bg-gray-900';
      break;
    case 'white':
      buttonColor = 'bg-white';
      break;
    default:
      buttonColor = color;
  }

  switch(size) {
    case 'small':
      buttonDimensions = 'w-32 h-10 px-5 py-2';
      iconSize = 'w-5 h-5';
      break;
    case 'medium':
      buttonDimensions = 'w-36 h-12 px-6 py-3';
      iconSize = 'w-6 h-6';
      break;
    case 'large':
      buttonDimensions = 'w-44 h-12 px-6 py-3';
      iconSize = 'w-6 h-8';
      break;
    default:
      buttonDimensions = size;
      iconSize = 'w-5 h-5';
  }


  return (
    <a
      className = {`rounded-full flex flex-row justify-between items-center cursor-pointer ${buttonColor} ${buttonDimensions} ${contentColor}`}
      href={link}
      target="_blank"
      rel="noreferrer"
      aria-label={`get the ${type} app`}
      {...props}
    >
     <div className={iconSize}>{buttonIcon}</div>
      <p className={`font-bold font-heading align-middle uppercase ${fontSize}`}>{cta}</p>
    </a>
  );
};

AppButton.propTypes = {
  type: PropTypes.string,
  cta: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
  link: PropTypes.string,
}

AppButton.defaults = {
  type: null,
  cta: null,
  color: null,
  size: null,
  link: null,
}

export default AppButton;