import React from 'react'

const connectionsSVG = <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M19 7.71515C18.9999 7.4829 18.8921 7.26381 18.7083 7.12191L10.4583 0.746944C10.188 0.537803 9.81051 0.537803 9.54025 0.746944L1.29025 7.12191C1.10695 7.26412 0.999788 7.48316 1 7.71515V17.8401C1 18.2543 1.33579 18.5901 1.75 18.5901H7.75C7.95711 18.5901 8.125 18.4222 8.125 18.2151V14.8401C8.125 13.8046 8.96447 12.9651 10 12.9651C11.0355 12.9651 11.875 13.8046 11.875 14.8401V18.2151C11.875 18.4222 12.0429 18.5901 12.25 18.5901H18.25C18.6642 18.5901 19 18.2543 19 17.8401V7.71515Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
</svg>;

const exploreSVG = <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="8.55325" cy="8.14334" r="7.55325" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M18.8717 18.462L13.8936 13.4839" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
;

const helpSVG = <svg width="27" height="20" viewBox="0 0 27 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.272 3.89929V10.4447" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M13.273 13.7175C13.499 13.7175 13.6821 13.9007 13.6821 14.1266" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M12.8634 14.1266C12.8634 13.9007 13.0465 13.7175 13.2725 13.7175" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M13.2725 14.5356C13.0465 14.5356 12.8634 14.3525 12.8634 14.1266" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M13.6821 14.1266C13.6821 14.3525 13.499 14.5356 13.273 14.5356" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
<circle cx="13.272" cy="9.62659" r="9" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M21.6162 18.6266C24.1216 16.3043 25.5456 13.0427 25.5456 9.62659C25.5456 6.21043 24.1216 2.94889 21.6162 0.626587" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M4.93042 0.626587C2.42441 2.94855 1 6.21022 1 9.62659C1 13.043 2.42441 16.3046 4.93042 18.6266" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
;

export {connectionsSVG, exploreSVG, helpSVG};
